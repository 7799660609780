<div class="container py-3 institutional-page">
    <div class="row">
        <div class="col">
            <img class="img-fluid my-4 rounded" src="/assets/images/DG_Powerball_Belinda_EX92.jpg">
            <h1>August 2024 Sire Highlights for Jetstream Genetics</h1>
        </div>
    </div>
</div>
<div class="container-fluid grey-bg">
    <div class="container institutional-page">
      <main class="row">
        <div class="col">
          <p>
            Madison, Wis. — The August 2024 Sire Summary includes outstanding results for the Jetstream
            Genetics lineup including exciting milking daughters from reliable Performance Proven Sires,
            new-release Jetset Priority Genomic Sires from renowned cow families, showring standouts
            with Custom Cut Type Sires, as well as opportunities with fertility specialist Reprojet Sires and
            the unique Robotic Sires.
          </p>
          <p>
            Priority Jetset Genomic Sire highlights:
          </p>
          <p>
            <b>734HO00165 PEAK MASINO-ET</b> (Tyrol x Upside x AltaZazzle) MASINO delivers a strong punch to
            the lineup as our No. 1 GTPI Sire for August 2024 with +3148 GTPI and +1.88 PTAT. MASINO is a
            complete package with +1757 Milk. +1067 NM$ with +70 Protein backed by a balanced linear
            trait type proof as well as A2A2. MASINO is a Tyrol x Upside x AltaZazzle x Positive x Medley x
            VG85 Octoberfest x VG Moonray x VG87 Bookem x VG86 Shottle x VG86 Oman Mirror x EX90
            Manat Mirage x EX91-2E GMD DOM Celsius x VG GMD DOM Melwood x VG GMD DOM Secret x
            EX91 GMD DOM Tony x VG87 GMD DOM x VG86 GMD DOM. MASINO is housed in Canada and
            qualifies for all international export markets.
          </p>
          <p>
            <b>734HO00164 COOKIECUTTER-PEAK HOCHI-ET</b> (Achieve x Moonshiner x Acura) HOCHI is a new
            Milk and NM$ Leader in the Jetstream lineup at +2416 Milk, +1207 NM$, +3125 GTPI and +79
            Protein with A2A2. Along with his outstanding production traits he’s at +1.75 UDC with a low
            +1.8 SCE. HOCHI is an Achieve x GP Moonshiner x Acura x VG88 Delta x EX90 Josuper x EX92-2E
            Montross x EX92 Epic x VG88 MOM Halo x VG88 DOM Goldwyn x EX90 GMD DOM Champion x
            EX90 GMD DOM Hershel x EX90-2E GMD DOM Rudolph x VG88 Elton x the famous Snow-N
            Denises Dellia EX95-2E GMD DOM. HOCHI is housed in Canada and qualifies for all international
            export markets.
          </p>
          <p>
            Proven Performance Sire highlights:
          </p>
          <p>
            <b>734HO00111 BLASKA</b> (Doc x Denver x Bombero) BLASKA is the No. 1 sire in the breed among all
            sires over +3.00 PTAT and over +2200 Milk. BLASKA is the highest PTAT daughter proven sire for
            Jetstream with +3.00 PTAT and +2.37 UDC along with +2266 Milk, a great linear profile adding
            strength, width, depth and open dairy frames along with positive teat length. BLASKA comes
            from a who’s-who of breeder, including Coldsprings, Morsan, Rocky Mountain and Rosy-Lane –
            these outstanding breeders have all had lasting impact on the pedigree of BLASKA. BLASKA is a
            King Doc x GP83 Denver x GP84 Bombero x GP82 McCuthchen x VG85 2* Planet x VG 1* Ramos
            x O-Man x Lynch x Goldfinger. BLASKA semen is readily available. He is housed in Canada and
            qualifies for all international export markets.
          </p>
          <p class="my-3">For more information, visit <a routerLink="/">www.jetstreamgenetics.com</a> or call
            <span>1.855.4JETSTREAM (855-453-8787)</span>.
          </p>
        </div>
      </main>
    </div>
</div>
